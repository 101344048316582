<template>
  <main class=" industries bg-gradient-to-r hidden  from-gray-300 to-gray-100 py-16 -z-10">


  <div class="grid lg:grid-cols-4 md:mx-5  justify-center items-center ">
  <div class="grid  lg:col-span-1 gap-y-6 justify-center items-center  mx-5">


<div class="   flex flex-col items-center justify-center"> <img src="../assets/industries/shipping.webp" class="w-3/5 rounded-full" alt=""><span class="text-center block font-semibold  my-2 lg:mt-3 text-sm sm:text-base">Economical Products</span></div>
<div class="  flex flex-col items-center justify-center "> <img src="../assets/industries/food.webp" class="w-3/5 rounded-full" alt=""><span class="text-center block font-semibold  my-2 lg:mt-3 text-sm sm:text-base">Constant Quality Analysis</span></div>

<!-- <div class="  flex flex-col items-center justify-center "> <img src="../assets/industries/pharma.webp" class="w-4/5 rounded-full" alt=""><span class="text-center block font-semibold  my-2 lg:mt-4">Pharmaceutical</span></div>
<div class="   flex flex-col items-center justify-center"> <img src="../assets/industries/electronics.webp" class="w-4/5 rounded-full" alt=""><span class="text-center block font-semibold  my-2 lg:mt-4">Electronics</span></div> -->

</div>

<div class="flex flex-col items-center justify-center lg:col-span-2">
<div class=" text-center pt-9 headingFont w-fit border-b-2 border-solid border-gray-600  pb-4 px-3">About Us</div>
<p class="text-lg md:text-xl bg-clip-text text-transparent bg-gradient-to-tl from-black to-[#000b2e] text-justify p-8">Moisture and humidity adversely affect the quality of products and severely harm their integrity.<br>
With over 25 years of experience in the industry, we at Desiccants India LLP have built a name for ourselves that is synonymous with reliability and quality.<br><br>

With a dedicated R&D team we aim to further push these standards and innovate the product to continue finding better, cheaper, and more effective solutions.
As an assurance of quality and our dedication to providing that, we have obtained the ISO-9001:2015 and the ISO-13485 certifications.

</p>



</div>



<div class="grid  lg:col-span-1 gap-y-6  justify-center items-center mx-5">


<!-- <div class="   flex flex-col items-center justify-center"> <img src="../assets/industries/shipping.webp" class="w-4/5 rounded-full" alt=""><span class="text-center block font-semibold  my-2 lg:mt-3 text-sm sm:text-base">Economical Products</span></div>
<div class="  flex flex-col items-center justify-center "> <img src="../assets/industries/food.webp" class="w-4/5 rounded-full" alt=""><span class="text-center block font-semibold  my-2 lg:mt-3 text-sm sm:text-base">Constant Quality Analysis</span></div> -->

<div class="  flex flex-col items-center justify-center "> <img src="../assets/industries/pharma.webp" class="w-3/5 rounded-full" alt=""><span class="text-center block font-semibold  my-2 lg:mt-4">Pharmaceutical</span></div>
<div class="   flex flex-col items-center justify-center"> <img src="../assets/industries/electronics.webp" class="w-3/5 rounded-full" alt=""><span class="text-center block font-semibold  my-2 lg:mt-4">Electronics</span></div>

</div>

</div>

  
  </main>
  <main class=" industries hidden bg-gradient-to-r  from-gray-300 to-gray-100 pb-16 -z-10">


  <div class="grid  md:mx-5  justify-center items-center ">
<div class="flex flex-col items-center justify-center lg:col-span-2">
<div class=" text-center pt-9 headingFont w-fit border-b-2 border-solid border-gray-600  pb-4 px-3">About Us</div>
<p class="text-lg md:text-xl bg-clip-text text-transparent text-center bg-gradient-to-tl from-black to-[#000b2e]  p-8">Moisture and humidity adversely affect the quality of products and severely harm their integrity.<br>
With over 25 years of experience in the industry, we at Desiccants India LLP have built a name for ourselves that is synonymous with reliability and quality.<br><br>

With a dedicated R&D team we aim to further push these standards and innovate the product to continue finding better, cheaper, and more effective solutions.
As an assurance of quality and our dedication to providing that, we have obtained the ISO-9001:2015 and the ISO-13485 certifications.

</p>



</div>

<div class="grid  grid-cols-4 lg:col-span-2 gap-x-2 gap-y-4  mx-5">


<div class="   flex flex-col items-center justify-center"> <img src="../assets/industries/shipping.webp" class="w-4/5 rounded-full" alt=""><span class="text-center block font-semibold  my-2 lg:mt-3 text-sm sm:text-base">Economical Products</span></div>
<div class="  flex flex-col items-center justify-center "> <img src="../assets/industries/food.webp" class="w-4/5 rounded-full" alt=""><span class="text-center block font-semibold  my-2 lg:mt-3 text-sm sm:text-base">Constant Quality Analysis</span></div>
<div class="  flex flex-col items-center justify-center "> <img src="../assets/industries/handicrafts.webp" class="w-4/5 rounded-full" alt=""><span class="text-center block font-semibold  my-2 lg:mt-3 text-sm sm:text-base">Environmentally Conscious</span></div>
<div class="   flex flex-col items-center justify-center"> <img src="../assets/industries/leather.webp" class="w-4/5 rounded-full" alt=""><span class="text-center block font-semibold  my-2 lg:mt-3 text-sm sm:text-base">Dedicated Service</span></div>
<!-- <div class="  flex flex-col items-center justify-center "> <img src="../assets/industries/pharma.webp" class="w-4/5 rounded-full" alt=""><span class="text-center block font-semibold  my-2 lg:mt-4">Pharmaceutical</span></div>
<div class="   flex flex-col items-center justify-center"> <img src="../assets/industries/electronics.webp" class="w-4/5 rounded-full" alt=""><span class="text-center block font-semibold  my-2 lg:mt-4">Electronics</span></div> -->

</div>

</div>

  
  
  
  </main>



  <main class=" industries  bg-gradient-to-r  from-gray-300 to-gray-100 py-16 -z-10">


  <div class="grid lg:grid-cols-7 md:mx-20 overflow-hidden justify-center items-center ">
<div v-motion
  :initial="{
    x:-100,
    opacity: 0,
  }"
  :visible="{
    x:0,

    opacity: 1,
    transition: {
      delay : 150,
      duration: 500,
      ease: 'easeInOut',
    },}"  class="flex flex-col items-center justify-center lg:col-span-4">
<div class=" text-center pt-9 headingFont w-fit border-b-2 border-solid border-gray-600  pb-4 px-3">About Us</div>
<p class="text-lg md:text-xl bg-clip-text text-transparent bg-gradient-to-tl from-black to-[#000b2e] text-justify p-8">Moisture and humidity adversely affect the quality of products and severely harm their integrity.<br>
With over 25 years of experience in the industry, we at Desiccants India LLP have built a name for ourselves that is synonymous with reliability and quality.<br><br>

With a dedicated R&D team we aim to further push these standards and innovate the product to continue finding better, cheaper, and more effective solutions.
As an assurance of quality and our dedication to providing that, we have obtained the ISO-9001:2015 and the ISO-13485 certifications.

</p>



</div>

<div class="grid  grid-cols-2 lg:col-span-3  gap-y-4  mx-5">


<div class="  xl:-mx-5  flex flex-col items-center justify-center" v-motion
  :initial="{
    x:100,
    opacity: 0,
  }"
  :visible="{
    x:0,

    opacity: 1,
    transition: {
      delay : 150,
      duration: 500,
      ease: 'easeInOut',
    },}"> <img src="../assets/about/iso.png" class="w-4/5 xl:p-10 xl:-m-10  " alt=""><span class="text-center block font-semibold  my-2 lg:mt-3 text-sm sm:text-base">ISO-9001:2015, <br>ISO-13485 Certified</span></div>

<div class="  xl:-mx-5  flex flex-col items-center justify-center" v-motion
  :initial="{
    x:100,
    opacity: 0,
  }"
  :visible="{
    x:0,

    opacity: 1,
    transition: {
      delay : 150,
      duration: 500,
      ease: 'easeInOut',
    },}"> <img src="../assets/about/dedicated_service.png" class="w-4/5 xl:p-10 xl:-m-10  " alt=""><span class="text-center block font-semibold  my-2 lg:mt-3 text-sm sm:text-base">Dedicated <br>Service</span></div>

<div class=" xl:-mx-5  flex flex-col items-center justify-center " v-motion
  :initial="{
    x:100,
    opacity: 0,
  }"
  :visible="{
    x:0,

    opacity: 1,
    transition: {
      delay : 150,
      duration: 500,
      ease: 'easeInOut',
    },}"> <img src="../assets/about/environmental.png" class="w-4/5 xl:p-10 xl:-m-10  " alt=""><span class="text-center block font-semibold  my-2 lg:mt-3 text-sm sm:text-base">Environmentally <br>Conscious</span></div>


    <div class=" xl:-mx-5  flex flex-col items-center justify-center " v-motion
  :initial="{
    x:100,
    opacity: 0,
  }"
  :visible="{
    x:0,

    opacity: 1,
    transition: {
      delay : 150,
      duration: 500,
      ease: 'easeInOut',
    },}"> <img src="../assets/about/quality.png" class="w-4/5 xl:p-10 xl:-m-10  " alt=""><span class="text-center block font-semibold  my-2 lg:mt-3 text-sm sm:text-base">Regular Quality<br> Analysis</span></div>

<!-- <div class="  flex flex-col items-center justify-center "> <img src="../assets/industries/pharma.webp" class="w-4/5 rounded-full" alt=""><span class="text-center block font-semibold  my-2 lg:mt-4">Pharmaceutical</span></div>
<div class="   flex flex-col items-center justify-center"> <img src="../assets/industries/electronics.webp" class="w-4/5 rounded-full" alt=""><span class="text-center block font-semibold  my-2 lg:mt-4">Electronics</span></div> -->

</div>

</div>

  
  
  
  </main>





</template>

<script>
export default {

}
</script>

<style>



</style>