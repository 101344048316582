<template>
<main class=" md:mt-12">


<h1 class="headingFont text-center mt-11 md:hidden" >Our Journey</h1>

  <div class="flex flex-col journeyPhone md:hidden justify-center items-center">
    <div class="flex w-full card md:p-24 p-10">
      <div class="w-3/5">
        <h3 class="font-bold text-2xl sm:text-3xl mb-2">Big Problems need Creative Solutions
</h3>

      <span class="text-xl text-white px-6 py-2 block text-justify w-fit my-2 rounded-xl sm:my-4  bg-[#00B0BD]">1994</span>
        
      </div>

      <div class="w-2/5">
        <lord-icon
    src="https://cdn.lordicon.com/zpxybbhl.json"
    trigger="loop"
    colors="primary:#69568A,secondary:#00B0BD"
    style="width:120%;height:120%; margin:-35px 0 0 -25px;">
</lord-icon>
      </div>
    </div>
   
    <p class="text-justify text-lg -mt-20 p-10">
          It began with a question- How to stop rapid damage of products due to moisture which is omnipresent?
        </p>
        

    <img
      src="../assets/arrow.png " class="h-20"
    />





    <div class="flex flex-row-reverse w-full card md:p-24 p-10">
      <div class="w-3/5 flex-col flex  justify-center">
        <h3 class="font-bold text-2xl sm:text-3xl mb-2">Creative Solutions seek Big Steps
</h3>

      <span class="text-xl text-white px-6 py-2 block text-justify w-fit my-2 rounded-xl sm:my-4  bg-[#00B0BD]">1998</span>
        
      </div>

      <div class="w-2/5">
        <lord-icon
    src="https://cdn.lordicon.com/ippkhukl.json"
    trigger="loop"
    colors="primary:#69568A,secondary:#00B0BD"
    style="width:130%;height:130%; margin: -30px 0 0px -25px;">
</lord-icon>
      </div>
    </div>

    <p class="text-justify text-lg p-10 -mt-16">
          Despite humble beginnings, our founder actively invested in and automated the silica gel manufacturing process through state-of-the-art technology. 
        </p>

     <img
      src="../assets/arrow.png" class="h-20"
    />






    <div class="flex w-full card md:p-24 p-10">
      <div class="w-3/5 flex flex-col justify-center">
        <h3 class="font-bold text-2xl sm:text-3xl  mb-2">Big Steps lead to Great Vision
</h3>

      <span class="text-xl text-white px-6 py-2 block text-justify w-fit my-2 rounded-xl sm:my-4  bg-[#00B0BD]">2003</span>
        
      </div>

      <div class="w-2/5">
        <lord-icon
    src="https://cdn.lordicon.com/gqdnbnwt.json"
    trigger="loop"
    colors="primary:#69568A,secondary:#00B0BD"
    stroke="45"
    scale="75"
    axis-x="55"
    axis-y="45"
    style="width:90%;margin:0 0 0 10px; height:100%;">
</lord-icon>
      </div>
    </div>

    <p class="text-justify text-lg p-10 -mt-20">
          Revolutionised the Indian market with a new type of adsorbent that opened doors to the pharmaceuticals and the food industry.
        </p>

     <img
      src="../assets/arrow.png" class="h-20"
    />



    <div class="flex w-full flex-row-reverse card md:p-24 p-10">
      <div class="w-3/5 flex flex-col justify-center">
        <h3 class="font-bold text-2xl sm:text-3xl mb-2">Great Vision builds a Reliable Team
</h3>

      <span class="text-xl text-white px-6 py-2 block text-justify w-fit my-2 rounded-xl sm:my-4  bg-[#00B0BD]">2010</span>
        
      </div>

      <div class="w-2/5">
        <lord-icon
    src="https://cdn.lordicon.com/uukerzzv.json"
    trigger="loop"
    colors="primary:#00B0BD,secondary:#69568A"
    stroke="55"
    style="width:100%;height:100%;">
</lord-icon>
      </div>
    </div>

    <p class="text-justify text-lg p-10 -mt-20">
         With a core investment in the trust of many, we built a team of over a 100 employees.
        </p>

     <img
      src="../assets/arrow.png" class="h-20"
    />


    <div class="flex w-full  card md:p-24 p-10">
      <div class="w-3/5 flex flex-col justify-center">
        <h3 class="font-bold text-2xl sm:text-3xl -mr-9 mb-2">Reliable Team thrives on Significant Purpose
</h3>

      <span class="text-xl text-white px-6 py-2 block text-justify w-fit my-2 rounded-xl sm:my-4  bg-[#00B0BD]">2019</span>
        
      </div>

      <div class="w-2/5">
        <lord-icon
    src="https://cdn.lordicon.com/gqzfzudq.json"
    trigger="loop"
    colors="primary:#00B0BD,secondary:#69568A"
    style="width:100%;height:100%; margin-left:20px;">
</lord-icon>
      </div>
    </div>
    <p class="text-justify text-lg p-10 -mt-20">
        Our founder is onto the next big question - how to protect material while also taking care of the environment?
        </p>


    
  </div>



























  <!-- laptop -->

  <section id="conference-timeline" class="hidden md:block mx-7">
  <div class="timeline-start mt-10 px-8 py-5 headingFont rounded-2xl whitespace-nowrap" >Our Journey</div>
    <div class="conference-center-line "></div>
    <div class="conference-timeline-content">
      <!-- Article -->
      <div class="timeline-article" >
        <div class="content-left-container" >
          <div class="content-left flex w-full card md:p-24  p-10">
            <div class="w-[400px]" >
              <h3 class="font-bold text-2xl lg:text-3xl mb-2 relative" >
                Big Problems need Creative Solutions
              </h3>
              <p class="lg:text-xl mt-5">
                It began with a question- How to stop rapid damage of products due to moisture which is omnipresent?
              </p>
            </div>

            <div class="w-40">
              <lord-icon
    src="https://cdn.lordicon.com/zpxybbhl.json"
    trigger="loop"
    colors="primary:#69568A,secondary:#00B0BD"
    style="width:130%;height:130%; margin:-25px 0 0 -25px;">
</lord-icon>
            </div>
          </div>
        </div>

        <div class="meta-date">
          <span class="date">1994</span>
          <span class="month"></span>
        </div>
      </div>
      <!-- // Article -->

      <!-- Article -->
      <div class="timeline-article">
        <div class="content-right-container">
          <div class="content-right flex flex-row-reverse">
            <div class="w-[400px]">
              <h3 class="font-bold text-2xl lg:text-3xl mb-2">
                Creative Solutions seek Big Steps
              </h3>
              <p class="lg:text-xl mt-5">
                Despite humble beginnings, our founder invested in and automated through state-of-the-art technology. We now had 4 machines.
              </p>
            </div>

            <div class="w-40">
              <lord-icon
    src="https://cdn.lordicon.com/ippkhukl.json"
    trigger="loop"
    colors="primary:#69568A,secondary:#00B0BD"
    style="width:130%;height:130%; margin: -30px 0 0px -25px;">
</lord-icon>
            </div>
          </div>
        </div>
        <div class="meta-date">
          <span class="date">1998</span>
          <span class="month"></span>
        </div>
      </div>
      <!-- // Article -->

      <!-- Article -->
      <div class="timeline-article">
        <div class="content-left-container">
          <div class="content-left flex w-full card md:p-24  p-10">
            <div class="w-[400px]">
              <h3 class="font-bold text-2xl lg:text-3xl mb-2">
                Big Steps lead to Great Vision
              </h3>
              <p class="lg:text-xl mt-5">
                Revolutionised theIindian market with a new type of adsorbent that opened doors to the pharmaceuticals and the food industry.
              </p>
            </div>

            <div class="w-40">
              <lord-icon
    src="https://cdn.lordicon.com/gqdnbnwt.json"
    trigger="loop"
    colors="primary:#69568A,secondary:#00B0BD"
    stroke="45"
    scale="75"
    axis-x="55"
    axis-y="45"
    style="width:100%; height:100%">
</lord-icon>
            </div>
          </div>
        </div>

        <div class="meta-date">
          <span class="date">2003</span>
          <span class="month"></span>
        </div>
      </div>
      <!-- // Article -->



      <div class="timeline-article">
        <div class="content-right-container">
          <div class="content-right flex flex-row-reverse">
            <div class="w-[400px]">
              <h3 class="font-bold text-2xl lg:text-3xl mb-2">
                Great Vision builds a Reliable Team

              </h3>
              <p class="lg:text-xl mt-5">
                With a core investment in the trust of many, we built a team of over 100 employees.
              </p>
            </div>

            <div class="w-40">
              <lord-icon
    src="https://cdn.lordicon.com/uukerzzv.json"
    trigger="loop"
    colors="primary:#00B0BD,secondary:#69568A"
    stroke="55"
    style="width:100%;height:100%;">
</lord-icon>
            </div>
          </div>
        </div>
        <div class="meta-date">
          <span class="date">2010</span>
          <span class="month"></span>
        </div>
      </div>



      <!-- Article -->
      <div class="timeline-article">
        <div class="content-left-container">
          <div class="content-left flex w-full card md:p-24  p-10">
            <div class="w-[400px]">
              <h3 class="font-bold text-2xl lg:text-3xl mb-2">
                Reliable Team thrives on Significant Purpose
              </h3>
              <p class="lg:text-xl mt-5">
                Our founder is onto the next big question - how to continue providing while also protecting the environment. 
              </p>
            </div>

            <div class="w-40">
              <lord-icon
    src="https://cdn.lordicon.com/gqzfzudq.json"
    trigger="loop"
    colors="primary:#69568A,secondary:#00B0BD"
    style="width:100%;height:100%">
</lord-icon>
            </div>
          </div>
        </div>

        <div class="meta-date">
          <span class="date">2019</span>
          <span class="month"></span>
        </div>
      </div>
    </div>
  </section>
</main>

</template>


  <style scoped>
body {
  background: #e6e6e6;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}




#conference-timeline .timeline-start,
#conference-timeline .timeline-end {
  display: table;


  font-weight: 900;
  background: #D1D5DB;



  text-align: center;
  margin: 0 auto;
}


/*===== Vertical Timeline =====*/
#conference-timeline {
  position: relative;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

#conference-timeline .conference-center-line {
  position: absolute;
  width: 3px;
  height: 96%;
  top: 0;
  left: 50%;
  margin-left: -2px;
  background: #D1D5DB;
  z-index: -1;
}
#conference-timeline .conference-timeline-content {
  padding-top: 67px;
  padding-bottom: 67px;
}
.timeline-article {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.timeline-article .content-left{
  margin-right: 20px !important;
}
.timeline-article .content-right{
  margin-left: 20px !important;
}
.timeline-article .content-left-container,
.timeline-article .content-right-container {

  max-width: 50%;
  width: 100%;
}

.timeline-article .content-left,
.timeline-article .content-right {
  position: relative;
  width: auto;
  padding: 15px 25px;
}
.timeline-article p {

  padding: 0;
  font-weight: 400;


  position: relative;
}


.timeline-article .content-right-container {
  float: right;
}
.timeline-article .content-left:before,
.timeline-article .content-right:before {
  position: absolute;
  top: 20px;
  font-size: 23px;
  font-family: "FontAwesome";
  color: #fff;
}
.timeline-article .content-left:before {
  content: "\f0da";
  right: -28px;
}
.timeline-article .content-right:before {
  content: "\f0d9";
  left: -28px;
}
.timeline-article .meta-date {
  position: absolute;
  top: 0;
  left: 50%;
  width: 62px;
  height: 62px;
  margin-left: -31px;
  color: #000;
  border-radius: 100%;
  background: #D1D5DB;
}
.timeline-article .meta-date .date,
.timeline-article .meta-date .month {
  display: block;
  text-align: center;
  font-weight: 900;
}
.timeline-article .meta-date .date {
  font-size: 20px;
  line-height: 60px;
}
.timeline-article .meta-date .month {
  font-size: 18px;
  line-height: 10px;
}



</style>

