<template>
  <div class="md:grid grid-cols-5 bg-[#E0FBFC] py-24">
  <div class="left flex flex-col md:col-span-3  mt-10 mx-7  p-5 rounded-2xl shadow-xl ">
  <h3 class="text-4xl font-bold border-b-2 pb-6  inline-block border-gray-400 border-solid"> About Us </h3>
  
  <p class="mt-6 text-lg ">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odio sunt perferendis adipisci inventore exercitationem nostrum dolores molestias reprehenderit nobis? Quaerat ipsum facilis, ullam veniam iste similique modi obcaecati? Quam modi neque atque molestiae, consectetur magni! Eos harum voluptas esse beatae cum incidunt sequi voluptatibus maiores, ducimus inventore dicta aliquam nesciunt nulla! Fugit mollitia nesciunt et? Suscipit ratione fugiat esse illo consequuntur labore, amet libero ea exercitationem facere, eaque doloribus quasi.</p>

  
  </div>

  <div class="flex right w-3/5 md:col-span-2 mx-auto mt-10 ">
  
  <img src="../assets/logo.png" width="100%" class="" alt="">
  </div>
  
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>